<template>
  <div>
    <div class="mx-2">
      <h3 class="title-header noprint mt-5">
        {{
          $getVisibleNames(
            "mesh.methodologicalstrategy",
            true,
            "Estrategias Metodológicas"
          )
        }}
        <AlternativeNameButton
          :table_name="'mesh.methodologicalstrategy'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <ButtonMedium
            v-can="'mesh.add_methodologicalstrategy'"
            v-if="allows_crud"
            :tooltip_text="`Agregar ${$getVisibleNames(
              'mesh.methodologicalstrategy',
              false,
              'Estrategia Metodológica'
            )}`"
            :text_button="`Agregar`"
            :click_button="
              () => $bvModal.show(`new-methodological-strategy-modal`)
            "
            :icon="'plus'"
            :variant="'primary'"
          >
          </ButtonMedium>
          <!-- <button
            v-can="'mesh.add_methodologicalstrategy'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm btn-add"
            @click="$bvModal.show(`new-methodological-strategy-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.methodologicalstrategy",
                false,
                "Estrategia Metodológica"
              )
            }}
          </button> -->
        </div>
      </div>
      <GenericBTable
        :items="methodological_strategies"
        :fields="methodologicalStrategyFields"
        :filter="input_search"
        :filterCustom="filterCustom"
        @emitChangeSearchField="changeInputSearch"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="'methodological_strategies'"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(description)="row">
          <div class="rich-text-content" v-html="row.item.description"></div>
        </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <ButtonSmall
            v-can="'mesh.change_methodologicalstrategy'"
            :tooltip_text="`Editar ${$getVisibleNames(
              'mesh.methodologicalstrategy',
              false,
              'Estrategia Metodológica'
            )}`"
            :click_button="
              () =>
                $bvModal.show(
                  `edit-methodological-strategy-modal-${row.item.id}`
                )
            "
            :class="``"
            :icon="'square'"
          ></ButtonSmall>
          <!-- <button-edit
            v-can="'mesh.change_methodologicalstrategy'"
            @click="
              $bvModal.show(`edit-methodological-strategy-modal-${row.item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.methodologicalstrategy',
                false,
                'Estrategia Metodológica'
              )}`
            "
          ></button-edit> -->
          <ButtonSmall
            v-can="'mesh.delete_methodologicalstrategy'"
            :click_button="
              () => askForDeleteMethodologicalStrategy(row.item.id)
            "
            :tooltip_text="`Eliminar ${$getVisibleNames(
              'mesh.methodologicalstrategy',
              false,
              'Estrategia Metodológica'
            )}`"
            :icon="'trash'"
          ></ButtonSmall>
          <!-- <button-delete
            v-can="'mesh.delete_methodologicalstrategy'"
            @click="askForDeleteMethodologicalStrategy(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.methodologicalstrategy',
                false,
                'Estrategia Metodológica'
              )}`
            "
          ></button-delete> -->
          <b-modal
            :id="`edit-methodological-strategy-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.methodologicalstrategy',
              false,
              'Estrategia Metodológica'
            )}`"
            size="lg"
            hide-footer
          >
            <MethodologicalStrategyForm
              :MethodologicalStrategy="row.item"
              @updated="slotUpdatedMethodologicalStrategy"
              :institution_id="row.item.school"
              :show_title="false"
            ></MethodologicalStrategyForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-methodological-strategy-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.methodologicalstrategy',
        false,
        'Estrategia Metodológica'
      )}`"
      size="lg"
    >
      <MethodologicalStrategyForm
        @created="slotCreatedMethodologicalStrategy"
        :institution_id="this.user.school"
      ></MethodologicalStrategyForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "MethodologicalStrategyView",
  components: {
    MethodologicalStrategyForm: () => import("./MethodologicalStrategyForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      methodological_strategies: names.METHODOLOGICAL_STRATEGIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "mesh.add_methodologicalstrategy"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    methodologicalStrategyFields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.methodologicalstrategy",
              true,
              "Estrategias Metodológicas"
            )
          ),
          sortable: true,
          display_column: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          display_column: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "is_active",
          label: "Vigencia",
          sortable: true,
          formatter: (value) => {
            return value ? "Si" : "No";
          },
          display_column: true,
          thClass: "text-left",
          tdClass: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.description, this.input_search) ||
        this.$filtered(row.is_active ? "Si" : "No", this.input_search)
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    slotCreatedMethodologicalStrategy() {
      this.$bvModal.hide("new-methodological-strategy-modal");
    },
    slotUpdatedMethodologicalStrategy(methodological_strategy) {
      this.$bvModal.hide(
        `edit-methodological-strategy-modal-${methodological_strategy.id}`
      );
    },
    askForDeleteMethodologicalStrategy(methodological_strategy_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.methodologicalstrategy",
          false,
          "Estrategia Metodológica"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_METHODOLOGICAL_STRATEGY,
              methodological_strategy_id
            )
            .then(() => {
              toast(
                this.$getVisibleNames(
                  "mesh.methodologicalstrategy",
                  false,
                  "Estrategia Metodológica"
                ) + " eliminada."
              );
            });
        }
      });
    },
  },
  beforeDestroy() {
    this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
    this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
    this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
  },
  created() {
    this.sortBy = "name";
    if (this.user) {
      this.$store.dispatch(
        names.FETCH_METHODOLOGICAL_STRATEGIES,
        this.user.school
      );
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, true);
        this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
          visible_intelligence_options: [7],
          disabled_selected_intelligence_option: true,
        });
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          intelligence_option: 7,
        });
      });
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.title-header {
  padding: 0 2rem;
}
.btn-add {
  margin-left: 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  width: 255px;
}
</style>