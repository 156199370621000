var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"title-header noprint mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", true, "Estrategias Metodológicas" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.methodologicalstrategy',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_methodologicalstrategy'),expression:"'mesh.add_methodologicalstrategy'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.methodologicalstrategy',
            false,
            'Estrategia Metodológica'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-methodological-strategy-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.methodological_strategies,"fields":_vm.methodologicalStrategyFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'methodological_strategies',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_methodologicalstrategy'),expression:"'mesh.change_methodologicalstrategy'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.methodologicalstrategy',
            false,
            'Estrategia Metodológica'
          )}`,"click_button":() =>
              _vm.$bvModal.show(
                `edit-methodological-strategy-modal-${row.item.id}`
              ),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_methodologicalstrategy'),expression:"'mesh.delete_methodologicalstrategy'"}],attrs:{"click_button":() => _vm.askForDeleteMethodologicalStrategy(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.methodologicalstrategy',
            false,
            'Estrategia Metodológica'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-methodological-strategy-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.methodologicalstrategy',
            false,
            'Estrategia Metodológica'
          )}`,"size":"lg","hide-footer":""}},[_c('MethodologicalStrategyForm',{attrs:{"MethodologicalStrategy":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedMethodologicalStrategy}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-methodological-strategy-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.methodologicalstrategy',
      false,
      'Estrategia Metodológica'
    )}`,"size":"lg"}},[_c('MethodologicalStrategyForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedMethodologicalStrategy}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }